@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.row {
  background: $primary-gradient;
  height: 100%;

  > * {
    color: $body-bg;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
}

.form {
  width: 100%;
  max-width: 500px;
}

.form input {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.link {
  color: $body-bg !important;
  transition: all 150ms ease-in-out;

  &:hover {
    color: darken($body-bg, 20%) !important;
    text-decoration: none;
  }
}
