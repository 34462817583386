.create {
  height: 430px;
  text-align: center;
  background: white url('/img/create-digital-display.svg') bottom no-repeat;
  padding-top: 20px;
}

.createContent {
  margin: auto;
  width: 250px;
}
