$drawerWidth: 260px;

$primary: #5d8ee9;
$primary-dark: #5d8ee9;
$primary-gradient: linear-gradient(0.25turn, #04d7e0, #5d8ee9, #a949f3, #f602fe);

$body-color: #545454; // text color

$main-darkest: #5d8ee9;
$main-dark: #5d8ee9;
$main: #5d8ee9;
$main-light: #5d8ee9;
$main-lightest: #5d8ee9;

$body-bg: #eef2f9;
$success: #63d75c;
$successText: white;
$info: $main-light;
