@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';

.main {
  min-height: 100%;
  margin-bottom: -50px;
}

.footer {
  height: 50px;

  > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.push {
  height: 50px;
}

.sidenav {
  display: none;
  top: 0;
  bottom: 0;
  position: fixed;
  width: $drawerWidth;
  background: $primary;
  color: #fff;

  & .sidenavLogo {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .sidenavIcon {
    font-size: 1.7rem;
    margin-top: -8px;
    margin-right: 20px;
  }

  & .sidenavItems {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    & .sidenavItem {
      color: white;
      padding: 20px 20px 20px 40px;
      font-size: 1.2rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        text-decoration: none;
        background-color: darken($primary, 3%);
      }
    }
  }
}

.content {
  margin-left: 0;
}

.active {
  background-color: white !important;
}

.list-group-item.active {
  background-color: white !important;
}

.list-group-item .active {
  background-color: white !important;
}

.navbar {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: 500;
  font-size: 1.2rem;
  border-bottom: solid 4px $main-lightest;
  background-color: $primary-dark;

  & .items {
    & > * {
      margin-left: 40px !important;
      padding-bottom: 4px !important;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    background-color: white;

    & * {
      color: $dark !important;
    }
  }

  .sidenav {
    display: block;
  }

  .content {
    margin-left: $drawerWidth;
  }
}
